<template>
  <div class="info-view">
    <div class="Lazy-img bg">
      <div class="van-image">
        <img
          src="https://qiniu.zhiyunys.com/zejiri/write_bg.png"
          class="van-image__img"
        />
      </div>
    </div>
    <div class="from-wrap">
      <div class="Lazy-img bg">
        <div class="van-image">
          <img
            src="https://qiniu.zhiyunys.com/zejiri/write_frame.png"
            class="van-image__img"
          />
        </div>
      </div>
      <div class="from">
        <div class="select" @click="showType = true">
          {{ title }}
          <div class="Lazy-img icon">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/write_select_time.png"
                class="van-image__img"
              />
            </div>
          </div>
        </div>
        <div
          v-if="title === '搬家吉日' || title === '入宅吉日'"
          class="house-wrap"
        >
          <div class="from-item">
            <div class="tit">住户1</div>
            <div class="line">
              <input
                v-model="userName1"
                type="text"
                placeholder="请输入姓名 (必须汉字）"
              />
            </div>
            <div class="line">
              <span
                style="cursor: pointer"
                @click="userSex1 = '男'"
                :class="`sex ${userSex1 === '男' ? 'active' : ''}`"
                >男</span
              >
              <span
                style="cursor: pointer"
                @click="userSex1 = '女'"
                :class="`sex  ${userSex1 === '女' ? 'active' : ''}`"
                >女</span
              >
            </div>
            <div class="line">
              <input
                id="birthday1"
                type="text"
                name="input_date"
                nolocal="true"
                data-toid-date="b_input"
                data-toid-hour="b_hour"
                data-confirm="true"
                readonly="readonly"
                data-type="0"
                value=""
                placeholder="请选择出生日期"
                class="input-value"
                v-model="showDate1"
                @click="onHandleChangeBirth('1')"
              />
            </div>
          </div>
          <div class="from-item">
            <div class="tit">住户2 <span>（选填）</span></div>
            <div class="line">
              <input
                v-model="userName2"
                type="text"
                placeholder="请输入姓名（必须汉字）"
              />
            </div>
            <div class="line">
              <span
                style="cursor: pointer"
                @click="userSex2 = '男'"
                :class="`sex ${userSex2 === '男' ? 'active' : ''}`"
                >男</span
              >
              <span
                style="cursor: pointer"
                @click="userSex2 = '女'"
                :class="`sex  ${userSex2 === '女' ? 'active' : ''}`"
                >女</span
              >
            </div>
            <div class="line">
              <input
                type="text"
                placeholder="请选择出生日期"
                id="birthday2"
                name="input_date2"
                nolocal="true"
                data-toid-date="b_input2"
                data-toid-hour="b_hour2"
                data-confirm="true"
                readonly="readonly"
                data-type="0"
                value=""
                class="input-value"
                v-model="showDate2"
                @click="onHandleChangeBirth('2')"
              />
            </div>
          </div>
        </div>
        <template
          v-if="
            title === '提车吉日' ||
            title === '开业吉日' ||
            title === '装修吉日' ||
            title === '动土吉日' ||
            title === '动工吉日' ||
            title === '出行吉日'
          "
        >
          <div class="line">
            <span class="label" style="width: 1.6rem;">姓名：</span
            ><input
              v-model="userName1"
              type="text"
              placeholder="请输入您的姓名(必须汉字）"
            />
          </div>
          <div class="line">
            <span class="label" style="width: 1.6rem;">生辰：</span
            ><input
              id="birthday1"
              type="text"
              name="input_date"
              nolocal="true"
              data-toid-date="b_input"
              data-toid-hour="b_hour"
              data-confirm="true"
              readonly="readonly"
              data-type="0"
              value=""
              placeholder="请选择您的出生年月日"
              class="input-value"
              v-model="showDate1"
              @click="onHandleChangeBirth('1')"
            />
          </div>
          <!-- <div class="line" @click="showRange = true">
            <span class="label">提车时间：{{columnsRange[typeRange]}}</span>
            <div class="Lazy-img icon">
              <div class="van-image">
                <img
                  src="https://qiniu.zhiyunys.com/zejiri/select_times.png"
                  class="van-image__img"
                />
              </div>
            </div>
          </div> -->
        </template>
        <div
          v-if="
            title === '结婚吉日' ||
            title === '订婚吉日' ||
            title === '领证吉日' ||
            title === '嫁娶吉日'
          "
          class="marry-wrap"
        >
          <div class="from-item">
            <div class="line">
              <input
                v-model="userName1"
                type="text"
                placeholder="请输入男方姓名(必须汉字）"
              />
            </div>
            <div class="line">
              <input
                id="birthday1"
                type="text"
                name="input_date"
                nolocal="true"
                data-toid-date="b_input"
                data-toid-hour="b_hour"
                data-confirm="true"
                readonly="readonly"
                data-type="0"
                value=""
                placeholder="男方出生日期"
                class="input-value"
                v-model="showDate1"
                @click="onHandleChangeBirth('1')"
              /><input id="b_input" type="hidden" name="date" /><input
                id="b_hour"
                type="hidden"
                name="hour"
              />
            </div>
          </div>
          <div class="from-item">
            <div class="line">
              <input
                v-model="userName2"
                type="text"
                placeholder="请女方姓名（必须汉字）"
              />
            </div>
            <div class="line">
              <input
                id="birthday2"
                type="text"
                name="input_date2"
                nolocal="true"
                data-toid-date="b_input2"
                data-toid-hour="b_hour2"
                data-confirm="true"
                readonly="readonly"
                data-type="0"
                value=""
                placeholder="女方出生日期"
                class="input-value"
                v-model="showDate2"
                @click="onHandleChangeBirth('2')"
              /><input id="b_input2" type="hidden" name="date" /><input
                id="b_hour2"
                type="hidden"
                name="hour"
              />
            </div>
          </div>
        </div>
        <div class="line">
          <span class="label"
            >何时{{ title.replace("吉日", "") }}：{{
              columnsRange[typeRange]
            }}</span
          >
          <div class="Lazy-img icon">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/select_times.png"
                class="van-image__img"
              />
            </div>
          </div>
        </div>
        <div class="btn" @click="submit">
          <div class="Lazy-img">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/frombtn.png"
                class="van-image__img"
              />
            </div>
          </div>
        </div>
        <div class="agreement-wrap">
          <div
            role="checkbox"
            tabindex="0"
            aria-checked="true"
            class="van-checkbox"
          >
            <div
              class="
                van-checkbox__icon
                van-checkbox__icon--round
                van-checkbox__icon--checked
              "
              style="font-size: 18px"
            >
              <i
                class="van-icon van-icon-success"
                style="
                  border-color: rgb(174, 50, 48);
                  background-color: rgb(174, 50, 48);
                "
                ><!----></i
              >
            </div>
          </div>
          <p>同意<span>《用户协议》</span>和<span>《隐私协议》</span></p>
        </div>
      </div>
    </div>
    <div class="info-tip">
      测试部分免费，获取结果或解锁全部内容时，需付费查看
    </div>
    <!---->
    <div class="van-overlay" style="z-index: 2001; display: none"></div>
    <div class="Lazy-img footer-label">
      <div class="van-image">
        <img
          src="https://qiniu.zhiyunys.com/zejiri/write_label.png"
          class="van-image__img"
        />
      </div>
    </div>
    <div class="fixed-menu">
      <div class="query-order" @click="jumpToOrder">
        <div class="Lazy-img" alt="">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/query-order.png"
              class="van-image__img"
            />
          </div>
        </div>
      </div>
      <!-- <div class="complaint" @click="jumpToHelp">
        <div class="Lazy-img" alt="">
          <div class="van-image">
            <img src="https://qiniu.zhiyunys.com/zejiri/complaint.png" class="van-image__img" />
          </div>
        </div>
      </div> -->
    </div>
    <div class="calendar" v-if="show">
      <van-datetime-picker
        class="picker"
        v-model="currentDate"
        type="datehour"
        :columns-order="['year', 'month', 'day']"
        :formatter="formatter"
        :min-date="minDate"
        :max-date="maxDate"
      >
        <slot name="default">
          <div class="switch-btn">
            <a :class="`${isLunar ? '' : 'active'}`" @click="changeFormatter()"
              >公历</a
            >
            <a :class="`${isLunar ? 'active' : ''}`" @click="changeFormatter()"
              >农历</a
            >
          </div>
        </slot>
      </van-datetime-picker>
      <div class="option-btn">
        <a @click="show = false">取消</a>
        <a @click="confirmDate">确定</a>
      </div>
    </div>
    <div class="type-picker" v-if="showType">
      <van-picker
        :show-toolbar="false"
        :columns="columns"
        :visible-item-count="4"
        :default-index="type"
        @change="onHandleChangeType"
      />
      <div class="option-btn">
        <a @click="showType = false">取消</a>
        <a @click="confirmType">确定</a>
      </div>
    </div>
    <div class="type-picker" v-if="showRange">
      <van-picker
        :show-toolbar="false"
        :columns="columnsRange"
        :visible-item-count="4"
        :default-index="typeRange"
        @change="onHandleChangeRange"
      />
      <div class="option-btn">
        <a @click="showRange = false">取消</a>
        <a @click="confirmTypeRange">确定</a>
      </div>
    </div>
  </div>
</template>

<script>
// import Calendar from '../components/calendar.vue';
import dayjs from "dayjs";
import api from "@/apis/order";
import calendar from "@/utils/calendar";

export default {
  data() {
    return {
      showRange: false,
      columnsRange: ["近一年", "近两年", "近三年"],
      typeRange: 2,
      columns: [
        "结婚吉日",
        "订婚吉日",
        "领证吉日",
        "搬家吉日",
        "入宅吉日",
        "提车吉日",
        "开业吉日",
        "装修吉日",
        "动土吉日",
        "嫁娶吉日",
        "动工吉日",
        "出行吉日",
      ],
      type: 0,
      showType: false,
      sex: "1",
      userSex1: "男",
      userSex2: "女",
      userName1: "",
      userName2: "",
      ///////////////
      minDate: new Date(1940, 1, 1),
      maxDate: new Date(),
      show: false,
      currentDate: new Date(1985, 7, 8),
      showDate1: "",
      usernBirthTime1: "",
      usergBirthTime1: "",
      normalDate1: "",
      showDate2: "",
      usernBirthTime2: "",
      usergBirthTime2: "",
      normalDate2: "",
      isLunar: false,
      formatter: (type, val) => {
        if (type === "hour") {
          return val + "时";
        }
        return val;
      },
      form: {
        name: "",
        sex: 0, // 0男 1女
        birthday: "",
      },
      isAgree: true,
    };
  },
  computed: {
    title() {
      return this.columns[this.type];
    },
  },
  components: {
    // Calendar
  },
  mounted() {
    this.type = this.$route.query.type || 0;
  },
  methods: {
    onHandleChangeType(picker, value, index) {
      this.type = index;
    },
    onHandleChangeRange(picker, value, index) {
      this.typeRange = index;
    },
    confirmType() {
      this.title = this.columns[this.type];
      this.showType = false;
    },
    confirmTypeRange() {
      // this.titleRange = this.columnsRange[this.typeRange];
      this.showRange = false;
    },
    submit() {
      if (
        this.title === "搬家吉日" ||
        this.title === "入宅吉日" ||
        this.title === "提车吉日" ||
        this.title === "开业吉日" ||
        this.title === "装修吉日" ||
        this.title === "动土吉日" ||
        this.title === "动工吉日" ||
        this.title === "出行吉日"
      ) {
        if (!this.userName1) {
          this.$toast("名字不能为空");
          return;
        }
        if (!this.usergBirthTime1) {
          this.$toast("请选择出生时间");
          return;
        }
      } else {
        if (!this.userName1 || !this.userName2) {
          this.$toast("名字不能为空");
          return;
        }
        if (!this.usergBirthTime1 || !this.usergBirthTime2) {
          this.$toast("请选择出生时间");
          return;
        }
      }
      const params = {
        luckyType: +this.type + 1,
        userName1: this.userName1,
        usergBirthTime1: this.usergBirthTime1,
        usernBirthTime1: this.usernBirthTime1,
        userSex1: this.userSex1,
        channel: this.$route.query.apkChannel,
        userName2: this.userName2,
        usergBirthTime2: this.usergBirthTime2,
        usernBirthTime2: this.usernBirthTime2,
        userSex2: this.userSex2,
        luckyName: this.columns[this.type],
      };
      console.log(params, "params");
      api.ceateOrder(params).then((res) => {
        if (res.data) {
          localStorage.setItem("odd", res.data.odd);
          localStorage.setItem("price", res.data.price);
          localStorage.setItem("payway", "");
          this.$router.push({
            path: `/result`,
            query: {
              range: this.columnsRange[this.typeRange],
              type: this.type,
              title: this.title,
              odd: res.data.odd,
            },
          });
          localStorage.setItem("createTime", new Date().getTime());
          localStorage.setItem("title", this.title);
          localStorage.setItem("type", this.type);
        }
      });
    },
    onHandleChangeBirth(val) {
      this.sex = val;
      this.show = true;
    },
    jumpToHelp() {
      this.$router.push("/help");
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
    confirmDate() {
      this.show = false;
      const year = this.currentDate.getFullYear();
      const month = this.currentDate.getMonth() + 1;
      const date = this.currentDate.getDate();
      const hour = this.currentDate.getHours();
      this[`showDate${this.sex}`] = `${
        this.isLunar ? "农(阴)历 " : "公(阳)历 "
      }`;
      if (this.isLunar) {
        const obj = calendar.lunar2solar(
          year,
          month,
          date,
          calendar.leapMonth(year) === month ? true : false
        );
        this[`usergBirthTime${this.sex}`] = `公(阳)历 ${obj.cYear}年${
          obj.cMonth
        }月${obj.cDay}日 (${calendar.getGzHour(hour)})时`;
        this[
          `usernBirthTime${this.sex}`
        ] = `农(阴)历 ${year}年${month}月${date}日 (${calendar.getGzHour(
          hour
        )})时`;
        this[`showDate${this.sex}`] = this[`usernBirthTime${this.sex}`];
        this[
          `normalDate${this.sex}`
        ] = `农(阴)历 ${year}年${month}月${date}日 ${calendar.getGzHour(
          hour
        )}时`;
      } else {
        const obj = calendar.solar2lunar(year, month, date);
        this[`usernBirthTime${this.sex}`] = `农(阴)历 ${obj.lYear}年${
          obj.lMonth
        }月${obj.lDay}日 ${hour}:00-${hour}:59(${calendar.getGzHour(hour)})时`;
        this[
          `usergBirthTime${this.sex}`
        ] = `公(阳)历 ${year}年${month}月${date}日 (${calendar.getGzHour(
          hour
        )})时`;
        this[`showDate${this.sex}`] = this[`usergBirthTime${this.sex}`];
        this[
          `normalDate${this.sex}`
        ] = `公(阳)历 ${year}年${month}月${date}日 ${calendar.getGzHour(
          hour
        )}时`;
      }
    },
    changeFormatter() {
      this.isLunar = !this.isLunar;
      this.formatter = (type, val) => {
        if (type === "hour") {
          return val + "时";
        }
        if (this.isLunar) {
          if (type === "year") {
            return val;
          }
          if (type === "month") {
            const temp = {
              "01": "正",
              "02": "二",
              "03": "三",
              "04": "四",
              "05": "五",
              "06": "六",
              "07": "七",
              "08": "八",
              "09": "九",
              10: "十",
              11: "冬",
              12: "腊",
            };
            return temp[val] + "月";
          }
          if (type === "day") {
            const temp = {
              "01": "初一",
              "02": "初二",
              "03": "初三",
              "04": "初四",
              "05": "初五",
              "06": "初六",
              "07": "初七",
              "08": "初八",
              "09": "初九",
              10: "初十",
              11: "十一",
              12: "十二",
              13: "十三",
              14: "十四",
              15: "十五",
              16: "十六",
              17: "十七",
              18: "十八",
              19: "十九",
              20: "二十",
              21: "甘一",
              22: "甘二",
              23: "甘三",
              24: "甘四",
              25: "甘五",
              26: "甘六",
              27: "甘七",
              28: "甘八",
              29: "甘九",
              30: "三十",
              31: "三一",
            };
            return temp[val];
          }
        }
        return val;
      };
    },
    getMsgFormSon(data) {
      if (Object.keys(data).length !== 0) {
        var disLength = data.lunarm.length;
        var shortName = data.lunarm.substring(disLength - 2, disLength);
        console.log(shortName);
        switch (shortName) {
          case "正月":
            this.m = 1;
            break;
          case "二月":
            this.m = 2;
            break;
          case "三月":
            this.m = 3;
            break;
          case "四月":
            this.m = 4;
            break;
          case "五月":
            this.m = 5;
            break;
          case "六月":
            this.m = 6;
            break;
          case "七月":
            this.m = 7;
            break;
          case "八月":
            this.m = 8;
            break;
          case "九月":
            this.m = 9;
            break;
          case "十月":
            this.m = 10;
            break;
          case "冬月":
            this.m = 11;
            break;
          case "腊月":
            this.m = 12;
            break;
          default:
        }
        this.y = data.lunary;
        this.dindex = data.dindex + 1;
        var time = this.y + "-" + this.m + "-" + this.dindex;
        this.BirthdaySR = dayjs(time).format("YYYY-MM-DD");
        this.userInfo.BirthdaySR = this.BirthdaySR + " " + "23:59:00";
        this.show = false;
        console.log(this.y + "-" + this.m + "-" + this.d);
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="less">
.info-view {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  padding: 1.533333rem 0.413333rem 0.626667rem 0.413333rem;
  text-align: center;
  box-sizing: border-box;
  background-color: #ae3230;
  font-size: 0.23rem;
  img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
  }
  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .van-image {
    position: relative;
    display: inline-block;
    .van-image__img {
      display: inline-block;
    }
  }
  .Lazy-img {
    display: inline-block;
    margin: 0;
    padding: 0;
    &.bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    .van-image {
      text-align: center;
      width: 100%;
      height: 100%;
    }
  }
  .from-wrap {
    position: relative;
    z-index: 2;
    background-color: #cda169;
    .bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .from {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      padding: 0.973333rem 0.413333rem 0.613333rem 0.413333rem;
      box-sizing: border-box;
      .select {
        position: relative;
        height: 1.333333rem;
        line-height: 1.333333rem;
        background: #fff0db;
        border-radius: 0.213333rem;
        font-size: 0.533333rem;
        font-family: SourceHanSansSC-Bold, SourceHanSansSC;
        font-weight: 700;
        color: #ae3230;
        border: 0.013333rem solid #cda169;
        margin-bottom: 0.266667rem;
        padding-right: 0.44rem;
        .icon {
          position: absolute;
          width: 0.32rem;
          bottom: 0;
          right: 2.8rem;
        }
      }
      .line {
        position: relative;
        height: 1.333333rem;
        line-height: 1.333333rem;
        padding: 0 0.346667rem;
        border: 0.013333rem solid #cda169;
        border-radius: 0.213333rem;
        margin-bottom: 0.28rem;
        text-align: left;
        font-size: 0.426667rem;
        background-color: #fff0db;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 6rem;
          height: 1.2rem;
          line-height: 1.2rem;
          border: none;
          outline: none;
          background-color: transparent;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          z-index: 9;
        }
        .label {
          font-weight: 700;
          color: #333;
        }
        .icon {
          position: absolute;
          width: 0.266667rem;
          right: 0.346667rem;
          bottom: 0;
        }
      }
      .sex {
        display: inline-block;
        width: 0.826667rem;
        height: 0.826667rem;
        line-height: 0.8rem;
        text-align: center;
        border-radius: 50%;
        margin-right: 0.4rem;
        border: 0.013333rem solid #ae3230;
        &.active {
          background-color: #ae3230;
          color: #fff;
        }
      }
      .btn {
        margin-top: 0.653333rem;
      }
      .house-wrap {
        .from-item {
          position: relative;
          height: 3.626667rem;
          background: url("https://qiniu.zhiyunys.com/zejiri/write_household_bg.png");
          background-size: 100% 100%;
          margin-bottom: 0.266667rem;
          padding-left: 1.733333rem;
          .tit {
            position: absolute;
            left: 0;
            top: 0;
            width: 1.733333rem;
            height: 3.626667rem;
            line-height: 3.626667rem;
            font-size: 0.426667rem;
            font-family: SourceHanSansSC-Bold, SourceHanSansSC;
            font-weight: 700;
            color: #333;
            span {
              position: absolute;
              left: 0.2rem;
              bottom: 1.066667rem;
              font-size: 0.32rem;
              font-family: SourceHanSansSC-Regular, SourceHanSansSC;
              font-weight: 400;
              color: #968368;
              line-height: 0.453333rem;
            }
          }
          .line {
            height: 1.2rem;
            line-height: 1.2rem;
            margin-bottom: 0;
            border: none;
            background-color: transparent;
            input {
              width: 6rem;
              height: 1.2rem;
              border: none;
              outline: none;
              background-color: transparent;
            }
          }
        }
      }
      .marry-wrap {
        .from-item {
          height: 2.453333rem;
          background: url("https://qiniu.zhiyunys.com/zejiri/write_woman_bg.png");
          background-size: 100% 100%;
          margin-bottom: 0.266667rem;
          padding-left: 1.733333rem;
          &:first-child {
            background-size: 100% 100% !important;
            background: url("https://qiniu.zhiyunys.com/zejiri/write_man_bg.png");
          }
          .line {
            height: 1.2rem;
            line-height: 1.2rem;
            margin-bottom: 0;
            border: none;
            background-color: transparent;
          }
        }
      }
      .agreement-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #755747;
        font-size: 0.32rem;
        text-align: center;
        margin-top: 0.333333rem;
        p {
          margin-left: 0.12rem;
        }
      }
    }
  }
  .info-tip {
    font-size: 0.32rem;
    font-weight: 400;
    color: #f98876;
    line-height: 0.466667rem;
    margin-top: 0.266667rem;
  }
  .footer-label {
    position: relative;
    z-index: 2;
    display: block;
    width: 5.56rem;
    margin: 0 auto;
    margin-top: 2.933333rem;
  }
  .type-picker {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    .van-picker {
      position: absolute;
      background-color: #fff;
      -webkit-user-select: none;
      user-select: none;
      bottom: 0;
      width: 100%;
      height: 436px;
      .van-picker-column__item--selected {
        color: #ae3230;
        font-weight: 700;
      }
    }
    .switch-btn {
      margin: 20px auto;
      a {
        display: inline-block;
        font-size: 0.453333rem;
        width: 3.026667rem;
        height: 1.12rem;
        font-weight: 700;
        line-height: 1.12rem;
        text-align: center;
        border-radius: 8px 0 0 8px;
        color: #ae3230;
        border: 0.01rem solid #ae3230;
        &:last-child {
          border-radius: 0 8px 8px 0;
          border-left: none;
        }
        &.active {
          background: #ae3230;
          color: #fff;
        }
      }
    }
    .option-btn {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      z-index: 999;
      padding: 20px 20px 160px;
      display: flex;
      justify-content: space-between;
      a {
        display: inline-block;
        width: 4.213333rem;
        height: 1.28rem;
        font-size: 0.426667rem;
        line-height: 1.28rem;
        text-align: center;
        &:first-child {
          background: url("https://qiniu.zhiyunys.com/img/cancel.png") no-repeat;
          background-size: 100% 100%;
          color: #666;
        }
        &:last-child {
          background: url("https://qiniu.zhiyunys.com/zejiri/time_bt_de.png")
            no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }
      }
    }
  }
  .calendar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 999;
    .van-picker__toolbar {
      height: auto;
    }
    .van-picker__columns {
      height: 336px !important;
      margin-bottom: 126px;
      box-shadow: 0 10px 10px 10px #fff;
    }
    .van-hairline-unset--top-bottom {
      height: auto !important;
    }
    .van-picker-column__item {
      color: #000;
      &--selected {
        color: #ae3230;
        font-weight: 700;
        & ~ li {
          color: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .van-hairline--top-bottom::after,
    .van-hairline-unset--top-bottom::after {
      border-width: 0;
    }
    .picker {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: url("https://qiniu.zhiyunys.com/img/calendar-bg.png")
        no-repeat;
      background-size: 100% 100%;
    }
    .switch-btn {
      margin: 20px auto;
      a {
        display: inline-block;
        font-size: 0.453333rem;
        width: 3.026667rem;
        height: 1.12rem;
        font-weight: 700;
        line-height: 1.12rem;
        text-align: center;
        border-radius: 8px 0 0 8px;
        color: #ae3230;
        border: 0.01rem solid #ae3230;
        &:last-child {
          border-radius: 0 8px 8px 0;
          border-left: none;
        }
        &.active {
          background: #ae3230;
          color: #fff;
        }
      }
    }
    .option-btn {
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      z-index: 999;
      padding: 20px 20px 160px;
      display: flex;
      justify-content: space-between;
      a {
        display: inline-block;
        width: 4.213333rem;
        height: 1.28rem;
        font-size: 0.426667rem;
        line-height: 1.28rem;
        text-align: center;
        &:first-child {
          background: url("https://qiniu.zhiyunys.com/img/cancel.png") no-repeat;
          background-size: 100% 100%;
          color: #666;
        }
        &:last-child {
          background: url("https://qiniu.zhiyunys.com/zejiri/time_bt_de.png")
            no-repeat;
          background-size: 100% 100%;
          color: #fff;
        }
      }
    }
  }
}
</style>