<template>
  <div class="result-view">
    <div class="Lazy-img">
      <div class="van-image">
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 0"
          src="https://qiniu.zhiyunys.com/zejiri/banner_0.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 1"
          src="https://qiniu.zhiyunys.com/zejiri/banner_1.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 2"
          src="https://qiniu.zhiyunys.com/zejiri/banner_2.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 3"
          src="https://qiniu.zhiyunys.com/zejiri/banner_3.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 4"
          src="https://qiniu.zhiyunys.com/zejiri/banner_4.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 5"
          src="https://qiniu.zhiyunys.com/zejiri/banner_5.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 6"
          src="https://qiniu.zhiyunys.com/zejiri/banner_6.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 7"
          src="https://qiniu.zhiyunys.com/zejiri/banner_7.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 8"
          src="https://qiniu.zhiyunys.com/zejiri/banner_8.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 9"
          src="https://qiniu.zhiyunys.com/zejiri/banner_9.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 10"
          src="https://qiniu.zhiyunys.com/zejiri/banner_10.png"
          class="van-image__img"
        />
        <img
          v-if="columns.findIndex((item) => item === luckyName) === 11"
          src="https://qiniu.zhiyunys.com/zejiri/banner_11.png"
          class="van-image__img"
        />
      </div>
    </div>
    <div class="result-list" id="list">
      <div class="item">
        <div class="title">
          <div class="Lazy-img">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/result_bg_msg.png"
                class="van-image__img"
              />
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wpraper">
            <div class="info-wrap" v-if="!infos[1].username2">
              <div class="item">
                <div class="head-wrap">
                  <div class="Lazy-img head">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/pay_unkown.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                  <div class="Lazy-img label">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/pay_label1.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </div>
                <p class="name">{{ infos[0].username1 }}</p>
                <p>{{ infos[0].usergBirthTime1 }}</p>
                <p>{{ infos[0].usernBirthTime1 }}</p>
                <!-- <div>
                  <div class="Lazy-img mingpang">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/lookmp.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <div class="info-wrap" v-else>
              <div class="item">
                <div class="head-wrap">
                  <div class="Lazy-img head">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/pay_unkown.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                  <div class="Lazy-img label">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/pay_label1.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </div>
                <div class="name">{{ infos[0].username1 }}</div>
                <div>
                  {{ infos[0].usergBirthTime1.split(" ")[1] }}
                  {{ infos[0].usergBirthTime1.split(" ")[2] }}
                </div>
                <div>
                  {{ infos[0].usernBirthTime1.split(" ")[1] }}
                  {{ infos[0].usernBirthTime1.split(" ")[2] }}
                </div>
                <!-- <div>
                  <div class="Lazy-img mingpang">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/lookmp.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
              <!-- <div class="love"></div> -->
              <div class="item">
                <div class="head-wrap">
                  <div class="Lazy-img head">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/pay_unkown.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                  <div class="Lazy-img label">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/pay_label2.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </div>
                <div class="name">{{ infos[1].username2 }}</div>
                <div>
                  {{ infos[1].usergBirthTime2.split(" ")[1] }}
                  {{ infos[1].usergBirthTime2.split(" ")[2] }}
                </div>
                <div>
                  {{ infos[1].usernBirthTime2.split(" ")[1] }}
                  {{ infos[1].usernBirthTime2.split(" ")[2] }}
                </div>
                <!-- <div>
                  <div class="Lazy-img mingpang">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/lookmp.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="Lazy-img down">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/result_bg_down.png"
              class="van-image__img"
            />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <div class="Lazy-img">
            <div class="van-image">
              <img
                src="https://qiniu.zhiyunys.com/zejiri/result_bg_select.png"
                class="van-image__img"
              />
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wpraper">
            <div class="date-wrap">
              <div class="tit">你选择的吉日日期范围为：</div>
              <ul class="nav">
                <li
                  @click="onHandleNavActive('all')"
                  :class="`${navActive === 'all' ? 'active' : ''}`"
                >
                  <span>所有吉日</span>
                  <div class="Lazy-img bg">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/result_time.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </li>
                <!-- <li class="">
                  <span>所有日子</span>
                  <div class="Lazy-img bg">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/result_time.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </li> -->
                <li
                  @click="onHandleNavActive('week')"
                  :class="`${navActive === 'week' ? 'active' : ''}`"
                >
                  <span>只看周末</span>
                  <div class="Lazy-img bg">
                    <div class="van-image">
                      <img
                        src="https://qiniu.zhiyunys.com/zejiri/result_time.png"
                        class="van-image__img"
                      />
                    </div>
                  </div>
                </li>
              </ul>
              <div
                v-for="(item, index) in luckyInfo"
                :key="index"
                :class="`mainitem ${
                  index === firstKey && activeKey ? 'open' : ''
                }`"
              >
                <div class="head">
                  {{ index }} 共{{ item.length }}天
                  <div
                    v-if="index === firstKey && activeKey"
                    class="more"
                    @click="showMore(index)"
                  >
                    收起
                    <div class="Lazy-img icon">
                      <div class="van-image">
                        <img
                          src="https://qiniu.zhiyunys.com/zejiri/result_open.png"
                          class="van-image__img"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else class="more" @click="showMore(index)">
                    详情
                    <div class="Lazy-img icon">
                      <div class="van-image">
                        <img
                          src="https://qiniu.zhiyunys.com/zejiri/result_open.png"
                          class="van-image__img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="calendar-list">
                  <div class="good" v-for="it in item" :key="it.title">
                    <div class="type">
                      <div class="Lazy-img icon">
                        <div class="van-image">
                          <img
                            src="https://qiniu.zhiyunys.com/zejiri/result_can.png"
                            class="van-image__img"
                          />
                        </div>
                      </div>
                      今日宜{{ luckyName.replace("吉日", "") }}
                    </div>
                    <div class="card">
                      <div class="info">
                        <div class="img">
                          <div class="Lazy-img calendar">
                            <div class="van-image">
                              <img
                                src="https://qiniu.zhiyunys.com/zejiri/result_calendar.png"
                                class="van-image__img"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="wrap">
                          <div class="cont">
                            <div class="datenum">{{ it.title }}</div>
                            <div>{{ it.desc }}</div>
                          </div>
                          <div class="after">
                            {{ getRestDays(it.title) }}天后
                          </div>
                        </div>
                      </div>
                      <!-- <div class="seemore open">
                        <div class="moreinfo">
                          <div class="tit">良辰吉时</div>
                          <div class="ji">
                            1:00-3:00:
                            该日1:00-3:00分搬家大吉，搬家期间，建议属羊之人暂时回避片刻。<br />9:00-11:00:
                            该日9:00-11:00分搬家大吉，搬家期间，建议属猪之人暂时回避片刻。<br />19:00-21:00:
                            该日19:00-21:00分搬家大吉，搬家期间，建议属龙之人暂时回避片刻。<br />21:00-23:00:
                            该日21:00-23:00分搬家大吉，搬家期间，建议属蛇之人暂时回避片刻。<br />
                          </div>
                          <div class="zhu">
                            注：以上提供的吉时为一天当中最好的时辰，可用于搬家等。可根据自身情况和当地风俗斟酌取用。<br />十二时辰宜忌有宜结婚的时辰区间，十二时辰相冲
                          </div>
                        </div>
                        <div class="downtit">
                          点击收起
                          <div class="Lazy-img icon">
                            <div class="van-image">
                              <img
                                src="https://qiniu.zhiyunys.com/zejiri/result_open.png"
                                class="van-image__img"
                              />
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="Lazy-img down">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/result_bg_down.png"
              class="van-image__img"
            />
          </div>
        </div>
      </div>
      <div class="item">
        <div class="title">
          <div class="Lazy-img">
            <div class="van-image">
              <img
              v-if="columns.findIndex((item) => item === luckyName) === 0"
              src="https://qiniu.zhiyunys.com/zejiri/title_0.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 1"
              src="https://qiniu.zhiyunys.com/zejiri/title_1.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 2"
              src="https://qiniu.zhiyunys.com/zejiri/title_2.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 3"
              src="https://qiniu.zhiyunys.com/zejiri/title_3.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 4"
              src="https://qiniu.zhiyunys.com/zejiri/title_4.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 5"
              src="https://qiniu.zhiyunys.com/zejiri/title_5.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 6"
              src="https://qiniu.zhiyunys.com/zejiri/title_6.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 7"
              src="https://qiniu.zhiyunys.com/zejiri/title_7.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 8"
              src="https://qiniu.zhiyunys.com/zejiri/title_8.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 9"
              src="https://qiniu.zhiyunys.com/zejiri/title_9.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 10"
              src="https://qiniu.zhiyunys.com/zejiri/title_10.png"
              class="van-image__img"
            />
            <img
              v-if="columns.findIndex((item) => item === luckyName) === 11"
              src="https://qiniu.zhiyunys.com/zejiri/title_11.png"
              class="van-image__img"
            />
            </div>
          </div>
        </div>
        <div class="content">
          <div class="wpraper">
            <div class="give">
              <div class="head">
                温馨提示
                <div class="Lazy-img bg">
                  <div class="van-image">
                    <img
                      src="https://qiniu.zhiyunys.com/zejiri/titlebg.png"
                      class="van-image__img"
                    />
                  </div>
                </div>
              </div>
              <div class="des">
                一、本服务属于命主八字和当天日子结合选出的吉日，而并非只看黄历宜忌。（如百度，搜狗等）
              </div>
              <div class="des">
                二、本服务未去除各地民俗忌搬家的日子，因各地民俗不同，可根据当地习俗自行去除。
              </div>
              <div class="des">
                三、本服务并无去除父母生日和忌日，如出现对应吉日，请根据真实情况自行决定是否选用。
              </div>
              <div class="head">
                {{ (tips || {}).title }}
                <div class="Lazy-img bg care">
                  <div class="van-image">
                    <img
                      src="https://qiniu.zhiyunys.com/zejiri/result_bg_care.png"
                      class="van-image__img"
                    />
                  </div>
                </div>
              </div>
              <ul>
                <li v-for="(item, index) in (tips || {}).list" :key="index">
                  <!--{{ intToChinese(index + 1) }}、-->
                  <div v-if="item.title" style="font-weight: bold">
                    {{ item.title }}
                  </div>
                  <div>{{ item.description }}</div>
                  <div v-for="(it, i) in item.list" :key="i">
                    <span style="font-weight: bold">{{ it.title }}</span
                    >：{{ it.content }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="Lazy-img down">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/result_bg_down.png"
              class="van-image__img"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="tabrbar">
      <div>
        <!-- <div class="Lazy-img">
          <div class="van-image">
            <img src="https://qiniu.zhiyunys.com/zejiri/tab3.png" class="van-image__img" />
          </div>
        </div>
        更多测算 -->
      </div>
      <div @click="$router.push('/help')">
        <div class="Lazy-img">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/tab1.png"
              class="van-image__img"
            />
          </div>
        </div>
        咨询老师
      </div>
      <div>
        <!-- <div class="Lazy-img">
          <div class="van-image">
            <img src="https://qiniu.zhiyunys.com/zejiri/kaiyun.png" class="van-image__img" />
          </div>
        </div>
        开运商城 -->
      </div>
    </div>
    <div class="fixed-menu">
      <div class="query-order" @click="jumpToOrder">
        <div class="Lazy-img" alt="">
          <div class="van-image">
            <img
              src="https://qiniu.zhiyunys.com/zejiri/query-order.png"
              class="van-image__img"
            />
          </div>
        </div>
      </div>
      <!-- <div class="complaint" @click="jumpToHelp">
        <div class="Lazy-img" alt="">
          <div class="van-image">
            <img src="https://qiniu.zhiyunys.com/zejiri/complaint.png" class="van-image__img" />
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import api from "@/apis/order";
import banner_0 from "../assets/img/banner_0.png";
import banner_1 from "../assets/img/banner_1.png";
import banner_2 from "../assets/img/banner_2.png";
import banner_3 from "../assets/img/banner_3.png";
import banner_4 from "../assets/img/banner_4.png";
import banner_5 from "../assets/img/banner_5.png";
import banner_6 from "../assets/img/banner_6.png";
import banner_7 from "../assets/img/banner_7.png";
import banner_8 from "../assets/img/banner_8.png";
import banner_9 from "../assets/img/banner_9.png";
import banner_10 from "../assets/img/banner_10.png";
import banner_11 from "../assets/img/banner_11.png";
import title_0 from "../assets/img/title_0.png";
import title_1 from "../assets/img/title_1.png";
import title_2 from "../assets/img/title_2.png";
import title_3 from "../assets/img/title_3.png";
import title_4 from "../assets/img/title_4.png";
import title_5 from "../assets/img/title_5.png";
import title_6 from "../assets/img/title_6.png";
import title_7 from "../assets/img/title_7.png";
import title_8 from "../assets/img/title_8.png";
import title_9 from "../assets/img/title_9.png";
import title_10 from "../assets/img/title_10.png";
import title_11 from "../assets/img/title_11.png";
// import calendar from "@/utils/calendar";

export default {
  data() {
    return {
      navActive: "all",
      titleImg: "",
      title_0,
      title_1,
      title_2,
      title_3,
      title_4,
      title_5,
      title_6,
      title_7,
      title_8,
      title_9,
      title_10,
      title_11,
      banner: "",
      banner_0,
      banner_1,
      banner_2,
      banner_3,
      banner_4,
      banner_5,
      banner_6,
      banner_7,
      banner_8,
      banner_9,
      banner_10,
      banner_11,
      title: localStorage.getItem("title"),
      type: localStorage.getItem("type"),
      active: 1,
      showKefu: false,
      info: {},
      curAnimal: "",
      animal: "",
      year: new Date().getFullYear(),
      infos: [{}, {}],
      luckyInfo: {},
      firstKey: "",
      activeKey: true,
      tips: {},
      luckyName: "",
      columns: [
        "结婚吉日",
        "订婚吉日",
        "领证吉日",
        "搬家吉日",
        "入宅吉日",
        "提车吉日",
        "开业吉日",
        "装修吉日",
        "动土吉日",
        "嫁娶吉日",
        "动工吉日",
        "出行吉日",
      ],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    intToChinese(num) {
      var AA = new Array(
        "零",
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十"
      );
      var BB = new Array("", "十", "百", "仟", "萬", "億", "点", "");
      var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
      for (var i = a[0].length - 1; i >= 0; i--) {
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0)
          re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
      }
      if (re == "一十") re = "十";
      if (re.match(/^一/) && re.length == 3) re = re.replace("一", "");
      return re;
    },
    copyObj(target) {
      // let resObj = JSON.parse(JSON.stringify(obj))
      // return resObj
      // 定义一个变量
      let result;
      // 如果当前需要深拷贝的是一个对象的话
      if (typeof target === "object") {
        // 如果是一个数组的话
        if (Array.isArray(target)) {
          result = []; // 将result赋值为一个数组，并且执行遍历
          for (let i in target) {
            // 递归克隆数组中的每一项
            result.push(this.copyObj(target[i]));
          }
          // 判断如果当前的值是null的话；直接赋值为null
        } else if (target === null) {
          result = null;
          // 判断如果当前的值是一个RegExp对象的话，直接赋值
        } else if (target.constructor === RegExp) {
          result = target;
        } else {
          // 否则是普通对象，直接for in循环，递归赋值对象的所有值
          result = {};
          for (let i in target) {
            result[i] = this.copyObj(target[i]);
          }
        }
        // 如果不是对象的话，就是基本数据类型，那么直接赋值
      } else {
        result = target;
      }
      // 返回最终结果
      return result;
    },
    onHandleNavActive(val) {
      this.navActive = val;
      let origin = this.copyObj(this.luckyInfoCopy);
      if (val === "week") {
        let obj = {};
        for (let key in origin) {
          const arr = origin[key].filter(
            (item) => item.period === "星期六" || item.period === "星期日"
          );
          if (arr.length !== 0) {
            obj[key] = [...arr];
          }
        }
        this.luckyInfo = obj;
      }
      if (val === "all") {
        this.luckyInfo = origin;
      }
    },
    getRestDays(date) {
      date = date
        .replace("公元", "")
        .replace("年", "-")
        .replace("月", "-")
        .replace("日", "");
      let nowTime = new Date(); //获取当前时间
      let setTime = new Date(date); //设置结束时
      let remianAllSeconds = Math.floor(
        (setTime.getTime() - nowTime.getTime()) / 1000
      ); //剩余总秒数
      return Math.floor(remianAllSeconds / (60 * 60 * 24)); //剩余天数
    },
    showMore(key) {
      if (this.firstKey !== key) {
        this.activeKey = true;
      } else {
        this.activeKey = !this.activeKey;
      }
      this.firstKey = key;
    },
    getInfo() {
      api
        .getInfo({
          out_trade_no: this.$route.query.odd,
        })
        .then((res) => {
          this.infos = res.userInfo;
          this.luckyName = res.luckyName;
          this.luckyInfo = this.copyObj(res.luckyInfo);
          this.luckyInfoCopy = this.copyObj(res.luckyInfo);
          this.firstKey = Object.keys(this.luckyInfo)[0];
          this.tips = res.tips;
          const index = this.columns.findIndex(
            (item) => item === this.luckyName
          );
          this.banner = this[`banner_${index}`];
          this.titleImg = this[`title_${index}`];
        });
    },
    jumpToHelp() {
      this.$router.push("/help");
    },
    jumpToOrder() {
      this.$router.push("/order");
    },
    copy() {
      const range = document.createRange();
      range.selectNode(document.getElementById("kefu"));
      const selection = window.getSelection();
      if (selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
    },
  },
};
</script>

<style lang="less" scoped>
.result-view {
  min-height: 100vh;
  background-color: #ae3230;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 2rem;
  max-width: 10rem;
  .van-image {
    position: relative;
    display: inline-block;
  }
  .van-image__error,
  .van-image__img,
  .van-image__loading {
    display: block;
    width: 100%;
    height: 100%;
  }
  .Lazy-img {
    display: inline-block;
    margin: 0;
    padding: 0;
    .van-image {
      text-align: center;
      width: 100%;
      height: 100%;
      .van-image__img {
        display: inline-block;
      }
    }
  }
  .result-list {
    .item {
      font-size: 0;
      position: relative;
      .down,
      .title {
        width: 100%;
      }
      .content {
        position: relative;
        z-index: 0;
        min-height: 3.666667rem;
        padding: 0 0.293333rem;
        .wpraper {
          background-color: #ffe2b8;
          .give {
            padding: 0.013333rem 0.426667rem;
            .head {
              position: relative;
              font-size: 0.48rem;
              font-family: SourceHanSansSC-Bold, SourceHanSansSC;
              font-weight: 700;
              color: #ae3230;
              line-height: 0.973333rem;
              text-align: center;
              margin-bottom: 0.373333rem;
              margin-top: 0.64rem;
              .bg {
                position: absolute;
                left: 50%;
                top: 0;
                margin-left: -1.56rem;
                width: 3.12rem;
                &.care {
                  // width: 5.466667rem;
                  width: 80%;
                  height: 0.96rem;
                  // margin-left: -2.733333rem;
                  transform: translateX(-50%);
                  margin-left: 0;
                }
              }
            }
            .des {
              font-size: 0.4rem;
              font-family: SourceHanSansSC-Regular, SourceHanSansSC;
              font-weight: 400;
              color: #333;
              line-height: 0.72rem;
              padding-bottom: 0.186667rem;
            }
            ul {
              li {
                font-size: 0.4rem;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                color: #333;
                line-height: 0.72rem;
                padding-bottom: 0.28rem;
                div {
                  font-weight: 400;
                  &:first-child {
                    font-weight: 700;
                  }
                }
              }
            }
          }
          .info-wrap {
            position: relative;
            display: flex;
            padding-top: 0.226667rem;
            .love {
              flex: 0 0 0.533333rem;
              padding-top: 1.053333rem;
            }
            .item {
              position: relative;
              flex: 1;
              font-size: 0.3rem;
              font-family: SourceHanSansSC-Regular, SourceHanSansSC;
              font-weight: 400;
              color: #333;
              line-height: 0.466667rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
              p {
                line-height: 1.5;
              }
              .mingpang {
                width: 2.573333rem;
                margin-top: 0.266667rem;
              }
              .head-wrap {
                display: inline-block;
                position: relative;
                margin-top: -0.013333rem;
                .head {
                  width: 1.893333rem;
                  height: 1.893333rem;
                }
                .label {
                  position: absolute;
                  width: 1.053333rem;
                  right: -0.666667rem;
                }
              }
              .name {
                font-size: 0.426667rem;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                font-weight: 700;
                color: #333;
                line-height: 0.613333rem;
                margin: 0.08rem 0;
              }
            }
          }
          .date-wrap {
            text-align: center;
            padding: 0 0.32rem;
            padding-bottom: 0.013333rem;
            .tit {
              font-size: 0.48rem;
              font-family: SourceHanSansSC-Bold, SourceHanSansSC;
              font-weight: 700;
              color: #333;
              line-height: 0.693333rem;
              margin-bottom: 0.466667rem;
              margin-top: -0.013333rem;
            }
            .nav {
              display: flex;
              padding: 0 0.133333rem;
              padding-bottom: 0.4rem;
              li {
                position: relative;
                cursor: pointer;
                flex: 1;
                width: 2.6rem;
                height: 1.066667rem;
                border-radius: 0.106667rem;
                border: 0.013333rem solid #ae3230;
                font-size: 0.4rem;
                font-family: SourceHanSansSC-Medium, SourceHanSansSC;
                font-weight: 500;
                color: #ae3230;
                line-height: 1.066667rem;
                margin-right: 0.346667rem;
                span {
                  position: relative;
                  z-index: 1;
                }
                .bg {
                  display: none;
                  position: absolute;
                  left: -0.013333rem;
                  top: 0;
                  z-index: 0;
                  // width: 2.6rem;
                  width: calc(100% + 2px);
                  height: 1.166667rem;
                }
                &.active {
                  span {
                    color: #fff;
                  }
                  .bg {
                    display: block;
                  }
                }
              }
            }
            .mainitem {
              .head {
                text-align: left;
                font-size: 0.426667rem;
                font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                font-weight: 700;
                color: #333;
                line-height: 1.28rem;
                border-bottom: 0.013333rem solid #e1c49b;
                .more {
                  position: relative;
                  float: right;
                  color: #8b7351;
                  font-size: 0.346667rem;
                  font-weight: 400;
                  padding-right: 0.346667rem;
                  .icon {
                    position: absolute;
                    width: 0.226667rem;
                    margin-left: 0.12rem;
                  }
                }
              }
              .calendar-list {
                display: none;
                .bad,
                .good {
                  .type {
                    font-size: 0.453333rem;
                    font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                    font-weight: 700;
                    color: #333;
                    line-height: 0.653333rem;
                    margin-bottom: 0.093333rem;
                    text-align: left;
                    padding-top: 0.213333rem;
                    .icon {
                      width: 0.56rem;
                      vertical-align: middle;
                      margin-right: 0.08rem;
                    }
                  }
                  .card {
                    .info {
                      display: flex;
                      .img {
                        flex: 0 0 1.146667rem;
                        height: 1.613333rem;
                        padding-top: 0.493333rem;
                        box-sizing: border-box;
                        background-color: #d3ac75;
                        .calendar {
                          width: 0.626667rem;
                        }
                      }
                      .wrap:last-child {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        padding: 0 0.32rem;
                        text-align: left;
                        color: #333;
                        font-size: 0.346667rem;
                        background-color: #ffecd1;
                        line-height: 2;
                        .cont {
                          flex: 1;
                          .datenum {
                            font-size: 0.453333rem;
                            font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                            font-weight: 700;
                            color: #ae3230;
                            line-height: 0.653333rem;
                          }
                        }
                        .after {
                          font-size: 0.32rem;
                          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
                          font-weight: 400;
                          color: #8b7351;
                          line-height: 0.466667rem;
                        }
                      }
                    }
                    .seemore {
                      border-top: 0.013333rem solid #d3ac75;
                      display: none;
                      .moreinfo {
                        .tit {
                          position: relative;
                          font-size: 0.4rem;
                          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                          font-weight: 700;
                          color: #333;
                          line-height: 1.16rem;
                          margin-bottom: 0;
                          text-align: center;
                        }
                        .ji,
                        .zhu {
                          font-size: 0.373333rem;
                          font-family: SourceHanSansSC-Bold, SourceHanSansSC;
                          color: #333;
                          line-height: 0.586667rem;
                          text-align: left;
                          padding: 0 0.293333rem;
                          margin-bottom: 0.266667rem;
                        }
                        .downtit {
                          position: relative;
                          font-size: 0.32rem;
                          font-family: SourceHanSansSC-Regular, SourceHanSansSC;
                          font-weight: 400;
                          color: #8b7351;
                          line-height: 0.906667rem;
                          text-align: center;
                          .icon {
                            transform: rotate(180deg);
                            bottom: -0.106667rem;
                          }
                        }
                      }
                      &.open {
                        .moreinfo {
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
              &.open {
                .head {
                  border-bottom: none;
                  .more {
                    .icon {
                      transform: rotate(180deg);
                      bottom: -0.066667rem;
                    }
                  }
                }
                .calendar-list {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  .tabrbar {
    display: flex;
    position: fixed;
    left: 50%;
    bottom: 0;
    width: 10rem;
    height: 1.4rem;
    z-index: 1000;
    transform: translateX(-50%);
    background-color: #fff;
    div {
      flex: 1;
      text-align: center;
      font-size: 0.28rem;
      color: #333;
      line-height: 0.4rem;
      .Lazy-img {
        display: block;
      }
      .van-image__img {
        display: inline-block;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-top: -0.226667rem;
        width: 1.12rem;
      }
    }
  }
}
</style>