<template>
  <div class="getorder">
    <div class="fix-order">
      <img src="https://qiniu.zhiyunys.com/img/order-check.png" @click="$router.push('/order')" />
    </div>
    <div class="protocol">
      <h3>隐私政策</h3>
      <div class="part part1">
        <p>我公司及测试服务平台（以下简称我平台）非常重视保护您的个人隐私。</p>
        <p>
          我平台已经开展了丰富多彩的内容业务，其中包括了在线测试。为方便您登录测试，以及为您提供更个性化的服务，您在使用我平台的服务时，我平台可能会收集和使用您的相关信息。为说明在线测试平台（以下简称平台）会如何收集、使用和存储您的个人信息及您享有何种权利，我平台将通过本协议向您阐述相关事宜，其中要点如下：
        </p>
        <p>
          1、我平台将逐一向您说明我平台收集的您的个人信息类型及其对应的用途，以便您了解我平台针对某一特定功能所收集的具体个人信息的类别、使用理由及收集方式。
        </p>
        <p>
          2、当您使用一些功能时，我平台会在获得您的同意后，收集您的一些隐私信息，例如提供测试服务时，我平台需要您的姓名和生辰或其他收集到的信息进行分析；为了保存您的分析报告结果，我平台需要获取您的手机或邮箱。如拒绝提供这些信息会使您无法使用相关特定功能。
        </p>
        <p>
          3、目前，在线测试平台不会主动共享或转让您的个人信息至我平台以外的第三方，如存在其他共享或转让您的个人信息情形时，我平台会征得您的明示同意，此外我平台会对对外提供信息的行为进行风险评估。
        </p>
        <p>
          4、您可以通过本声明所列途径访问、更正、删除您的个人信息，也可以撤回同意、注销帐号、投诉举报。
        </p>
        <p>
          您使用我平台的服务，即意味着您已经同意我平台按照本隐私政策收集、使用、储存和披露您的相关信息，以及向您提供的控制和保护措施。
        </p>
      </div>
      <div class="part">
        <ul class="list">
          <li class="list-item">
            <h4>一、信息收集范围</h4>
            <p>
              在您使用在线测试服务的过程中，本平台会按照如下方式收集您在使用服务时主动提供或因为使用服务而产生的信息，用以向您提供服务、优化我平台的服务以及保障您的帐号安全：
            </p>
            <p>
              1.1
              当您进入某些测试服务前（较少），我平台可能会获取您的微信头像、微信昵称信息，收集微信头像和昵称信息是为了帮助您完成测试报告的归属认证，保障您的报告评测结果与您的账号相关联并保留。
            </p>
            <p>
              1.2
              在您体验测试服务时，我平台需要获取您的姓名和生辰（出生日期），为了保证数据准确性，我平台建议您提供真实的个人信息，若您提供的信息有错误，会对测试结果的准确性造成偏差。我平台保证您的所有个人信息除了用于生成测试数据以外，不会用于其他用途。
            </p>
            <p>
              1.3
              当您体验完测试服务后，您可以主动保存分析报告结果，这时候我平台需要获取您的手机或邮箱，用来发送报告结果，方便你进行查看。
            </p>
          </li>
          <li class="list-item">
            <h4>二、信息的存储</h4>
            <p>2.1 信息存储的地点</p>
            <p>
              我平台会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。
            </p>
            <p>2.2 信息存储的期限</p>
            <p>
              一般而言，我平台仅为实现目的所必需的时间保留您的个人信息，例如：微信头像和微信昵称，若您需要使用测试服务，我平台需要一直保存您的微信头像和微信昵称，以保证您正常使用该服务，当您一段时间未进入网页或程序后，我平台将删除相应的信息；当我平台的产品或服务发生停止运营的情形时，我平台将以推送微信服务消息通知、短信等形式通知您，并在合理的期限内删除您的个人信息或进行匿名化处理。
            </p>
          </li>
          <li class="list-item">
            <h4>三、信息安全</h4>
            <p>
              我平台努力为用户的信息安全提供保障，以防止信息的丢失、不当使用、未经授权访问或披露。
            </p>
            <p>
              我平台将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我平台会使用加密技术（例如，SSL）、匿名化处理等手段来保护您的个人信息。我平台通过不断提升的技术手段加强我平台产品的安全能力，以防止您的个人信息泄露。例如，我平台为了安全传输会在您的设备本地完成部分信息加密的工作。我平台建立专门的管理制度、流程和组织以保障信息的安全。例如，我平台严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我平台会启动应急预案，
              阻止安全事件扩大，并以公告、 短信等形式告知您。
            </p>
          </li>
          <li class="list-item">
            <h4>四、 信息的使用</h4>
            <p>
              在现行法律法规允许的范围内，我平台可能将通过某些功能所收集的信息用于我平台的其他服务。例如，本平台可能会将您非隐私的个人信息用于市场营销，使用方式包括但不限于：
            </p>
            <p>• 在网页或者app平台中向您展示或提供广告和促销资料。</p>
            <p>• 向您通告或推荐服务或产品信息。</p>
            <p>
              •
              使用电子邮件，短信等方式推送其他此类根据您使用我平台服务或产品的情况所认为您可能会感兴趣的信息。
            </p>
            <p>
              如我平台使用您的个人信息，超出了与收集时所声称的目的及具有直接或合理关联的范围，我平台将在使用您的个人信息前，再次向您告知并征得您的明示同意。
            </p>
          </li>
          <li class="list-item">
            <h4>五、关于Cookie的使用</h4>
            <p>
              为确保网页正常运转,为您提供更为便捷的访问体验,我平台会在您的计算机或移动设备上存储名为
              Cookie
              B的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于cookie,网页或客户端能够存储您的偏好或历史报告等数据。我平台不会将Cookie用于本协议所述目的之外的任何用途。您可根据自己的偏好管理或清除移动设备（或计算机）上保存的所有
              cookie。
            </p>
            <p>
              但如果您这么做则会影响我平台为您提供更好的用户使用体验以及更方便的服务。
            </p>
          </li>
          <li class="list-item">
            <h4>六、信息共享、转让、披露</h4>
            <p>
              除按法律法规规定外，我平台不会主动对外公开披露所收集的个人信息，如须主动公开披露时，我平台会向您告知此次公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明示同意。
            </p>
            <p>
              本平台也不会主动共享或转让您的个人信息至我平台以外的第三方，如存在其他共享或转让您的个人信息情形时，我平台会征得您的明示同意，此外我平台会对对外提供信息的行为进行风险评估。
            </p>
            <p>
              随着我平台业务的持续发展，我平台将按照法律法规及不低于本声明所要求的标准继续保护或要求新的控制者继续保护您的个人信息。
            </p>
          </li>
          <li class="list-item">
            <h4>七、隐私政策的更新</h4>
            <p>
              我平台可能会适时对本声明进行修订。当声明的条款发生变更时，将会在网页的重要位置上提示修改内容。请您注意，只有在您勾选同意按钮后，我平台才会按照更新后的声明收集、使用、存储您的个人信息。
            </p>
            <p></p>
          </li>
          <li class="list-item">
            <h4>八、其他</h4>
            <p>
              在线测试隐私政策中所规定的信息安全保障措施均适用于在线测试平台全体用户。
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.getorder {
  font-size: 0.32rem;
  .protocol {
    padding: 0.3rem;
  }
  h3 {
    font-size: .3rem;
    text-align: center;
    padding-bottom: 0.2rem;
    margin: 15px 0;
    font-weight: bold;
  }
  .part {
    font-size: .29rem;
    padding-bottom: 0.1rem;
    p {
      line-height: .45rem;
      color: #333;
      text-align: justify;
      text-indent: 2em;
      margin-bottom: 0.1rem;
    }
  }
  .fix-order {
    position: fixed;
    z-index: 100;
    right: 0;
    top: 40%;
  }

  .fix-order img {
    width: 0.6rem;
  }

  img {
    width: 100%;
  }

  p {
    margin: 0;
  }
}
</style>