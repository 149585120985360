<template>
  <div class="order">
    <div class="fix-order">
      <img src="https://qiniu.zhiyunys.com/img/order-fund.png" @click="$router.push('/guide')"/>
    </div>
    <div class="search" v-if="!isPhone">
      <p class="tips">
        <span>请输入订单号查看测算结果：</span>
        <span class="how" @click="jumpToGuide"
          ><img src="https://qiniu.zhiyunys.com/img/icon_wenhao.png" />如何获取订单号</span
        >
      </p>
      <p class="input">
        <input
          v-model="formOrder"
          type="text"
          placeholder="请输入您要查询的订单号"
        />
        <a @click="search">立即查询</a>
      </p>
      <p class="phone-search">
        <span @click="jumpToMain">首页</span>
        <span class="use-phone" @click="changeTab(true)"
          ><img src="https://qiniu.zhiyunys.com/img/phone.png" />手机号码查询</span
        >
      </p>
    </div>
    <div class="search phone" v-if="isPhone">
      <p class="tips">
        <span>短信验证，即可查看已付款的测算结果：</span>
      </p>
      <p class="input">
        <input v-model="form.phone" type="text" maxlength="11" oninput = "value=value.replace(/[^\d]/g,'')"  placeholder="请输入手机号码">
      </p>
      <!-- <p class="input">
        <input v-model="form.code" type="text" placeholder="请输入验证码" />
        <a v-if="time < 0" @click="getCode">获取验证码</a>
        <a v-if="time >= 0">{{ time }}s后重新获取</a>
      </p> -->
      <p class="input submit">
        <a @click="search">立即查询</a>
      </p>
      <p class="phone-search">
        <span @click="jumpToMain">首页</span>
        <span class="use-phone" @click="changeTab(false)">订单号查询订单</span>
      </p>
    </div>
    <div class="history">
      <h2 class="title">历史订单</h2>
      <div class="card" v-for="item in list" :key="item.odd">
        <p>
          <label>测算名称：</label>
          <span>{{ item.orderType }}</span>
        </p>
        <p>
          <label>姓名：</label>
          <span>{{ item.userName }}</span>
        </p>
        <p>
          <label>订单号：</label>
          <span>{{ item.odd }}</span>
        </p>
        <p>
          <label>状态：</label>
          <span>{{ item.orderStatus }}</span>
        </p>
        <p class="btns">
          <a class="btn" @click="deleteOrder(item)">删除记录</a>
          <a class="btn" @click="jumpToResult(item)">点击查看</a>
        </p>
      </div>
    </div>
    <div class="security">
      <div class="help">
        <span>如需帮助点此</span>
        <span @click="jumpToHelp" class="kefu"
          ><img
            src="https://qiniu.zhiyunys.com/img/kefu_icon.png"
          />请联系专属售后客服</span
        >
      </div>
      <img src="https://qiniu.zhiyunys.com/img/bottom_1.png" class="pic1" />
      <img src="https://qiniu.zhiyunys.com/img/bottom_2.png" class="pic2" />
    </div>
  </div>
</template>

<script>
import api from "@/apis/order";

export default {
  data() {
    return {
      isPhone: false,
      form: {
        phone: "",
        code: "",
      },
      formOrder: "",
      timer: null,
      time: -1,
      list: [],
      tabStatus: -1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    changeTab(val) {
      this.isPhone = val;
      if (val) {
        this.formOrder = "";
        this.tabStatus = 0; // phone
      } else {
        this.form = {};
        this.tabStatus = 1; // order
      }
    },
    jumpToMain() {
      this.$router.push("/main");
    },
    jumpToResult(item) {
      localStorage.setItem('odd', item.odd);
      // localStorage.setItem("title", item.title);
      // localStorage.setItem("type", item.type);
      localStorage.setItem('payway', '');
      if (item.status === 0) {
        this.$router.push({
          path: `/result`,
          query: {
            odd: item.odd
          }
        });
        return;
      }
      this.$router.push({
        path: `/resultPayed`,
        query: {
          odd: item.odd
        }
      });
    },
    jumpToGuide() {
      this.$router.push("/guide");
    },
    jumpToHelp() {
      this.$router.push("/help");
    },
    search() {
      if (this.isPhone && !this.form.phone) {
        this.$toast("手机号码不能为空");
        return;
      }
      // if (this.isPhone && !this.form.code) {
      //   this.$toast("验证码不能为空");
      //   return;
      // }
      if (!this.isPhone && !this.formOrder) {
        this.$toast("请输入要查询的订单号");
        return;
      }
      if (this.tabStatus < 0) {
        this.tabStatus = 1;
      }
      this.getList();
    },
    getList() {
      this.tabStatus === 0 &&
        api
          .getOrder({
            phoneNumber: this.form.phone,
          })
          .then((res) => {
            if (res.message) {
              this.$toast(res.message);
              return;
            }
            this.list = res;
          });
      this.tabStatus === 1 &&
        api
          .getOrder({
            odd: this.formOrder,
          })
          .then((res) => {
            if (res.message) {
              this.$toast(res.message);
              return;
            }
            this.list = res;
          });
      this.tabStatus < 0 &&
        api.getList().then((res) => {
          if (res.message) {
            this.$toast(res.message);
            return;
          }
          this.list = res;
        });
    },
    deleteOrder(item) {
      api
        .deleteOrder({
          userName: item.userName,
          odd: item.odd,
        })
        .then((res) => {
          this.$toast(res.message);
          this.getList();
        });
    },
    getCode() {
      this.time = 60;
      this.timer = setInterval(() => {
        if (this.time === -1) {
          clearInterval(this.timer);
        } else {
          this.time--;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less">
.order {
  height: 100%;
  min-height: 718px;
  background: #f6f7f8;
  font-size: 0.32rem;
  .search {
    padding: 20px;
    color: #666;
    font-size: 14px;
    background: #fff;
    .tips {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .how {
        color: #c20d15;
        img {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          vertical-align: middle;
        }
      }
    }
    .input {
      width: calc(100% - 20px);
      padding: 0 10px;
      padding-right: 0;
      border: 0.5px solid #c20d15;
      border-radius: 6px;
      display: flex;
      input {
        width: 70%;
        height: 52px;
        line-height: 52px;
        font-size: 16px;
        background: url("https://qiniu.zhiyunys.com/img/search.png") no-repeat left 18px;
        background-size: 18px 18px;
        padding-left: 20px;
        border-radius: 4px 0 0 4px;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      input::-moz-placeholder {
        color: #ccc;
      }
      input:-ms-input-placeholder {
        color: #ccc;
      }
      input::-webkit-input-placeholder {
        color: #ccc;
      }
      input::placeholder {
        color: #ccc;
      }
      a {
        width: 30%;
        height: 54px;
        line-height: 54px;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        color: #fff;
        text-align: center;
        background: #c20d15;
        border-radius: 0 4px 4px 0;
      }
    }
    .phone-search {
      width: 226px;
      font-size: 14px;
      color: #333;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      .use-phone {
        text-decoration: underline;
        img {
          height: 19px;
          vertical-align: middle;
        }
      }
    }
    &.phone {
      .input {
        border: 0.5px solid #bebdbd;
        margin-bottom: 10px;
        padding: 0;
        width: 100%;
        input {
          background: none;
          padding-left: 10px;
        }
        a {
          color: #c20d15;
          background: #fff;
          border-radius: 6px;
          font-weight: normal;
        }
        &.submit {
          border: 0.5px solid #c20d15;
          a {
            color: #fff;
            width: 100%;
            background: #c20d15;
          }
        }
      }
    }
  }
  .history {
    padding: 20px 15px;
    .title {
      font-size: 16px;
      font-weight: bold;
      line-height: 2;
      margin-bottom: 10px;
    }
    .card {
      border-radius: 4px;
      background: #fff;
      padding: 10px;
      p {
        display: flex;
        justify-content: space-between;
        line-height: 2;
        font-size: 14px;
        label {
          color: #999;
        }
        span {
          color: #333;
        }
        &.btns {
          display: block;
          text-align: right;
          margin-top: 10px;
          .btn {
            width: 86px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 4px;
            margin-left: 10px;
            display: inline-block;
            &:first-child {
              border: 0.5px solid #d1d1d1;
              color: #999;
            }
            &:last-child {
              border: 0.5px solid #c20d15;
              color: #c20d15;
            }
          }
        }
      }
    }
  }
  .security {
    margin-top: 40px;
    text-align: center;
    .help {
      display: flex;
      justify-content: center;
      font-size: 12px;
      span {
        line-height: 20px;
      }
      .kefu {
        color: #c20d15;
        text-decoration: underline;
      }
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 5px;
      }
    }
    .pic1 {
      width: 100%;
      margin-bottom: 10px;
    }
    .pic2 {
      width: 244px;
    }
  }
}
</style>