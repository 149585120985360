<template>
  <div class="getorder">
    <div class="fix-order">
      <img src="https://qiniu.zhiyunys.com/img/order-check.png" @click="$router.push('/order')"/>
    </div>
    <div class="protocol">
      <h3>用户服务协议</h3>
      <div class="part part1">
        <p>引言</p>
        <p>欢迎您使用我司的在线测试服务平台！</p>
        <p>
          我司的在线测试服务平台（以下简称“我平台”）根据《用户服务协议》（以下简称“本协议”）及其他补充协议（以下简称“补充协议”）为您提供服务。
        </p>
        <p>
          您在使用我平台服务前请务必审慎阅读、充分理解本协议及补充协议各条款内容，特别是免除或者限制责任的条款、对用户权利进行限制的条款等。
        </p>
        <p>
          我平台有权根据实际经营情况、国际相关法律法规的调整，调整本协议的任何条款或增加其他的补充协议。补充协议与本协议具有相同的法律效力。
        </p>
        <p>
          除非您已充分阅读、完全理解并接受本协议及补充协议的所有条款，否则您无权使用我平台服务。
        </p>
        <p>
          您选择开始或购买相关测试服务的行为或者以其他任何明示或者默示方式表示接受本协议的，均视为您已阅读并同意签署本协议。
        </p>
        <p>
          如果您因年龄、智力等因素而不具有完全民事行为能力，请在法定监护人（以下简称“监护人”）的陪同之下阅读和签署本协议，但我平台仍有权拒绝向限制民事行为能力人提供任何服务。
        </p>
        <p>
          您有违反本协议或本协议之补充协议的任何行为时，我平台有权根据您违反规定的情形单方采取限制、中止或终止向您提供服务等措施，并有权追究您相关责任。
        </p>
      </div>
      <div class="part">
        <ul class="list">
          <li class="list-item">
            <h4>一、协议适用范围</h4>
            <div>
              <p>
                本协议及补充协议是我平台制定的关于获取我平台服务的相关规则。本规则适用于由我平台提供的所有测试服务。
              </p>
            </div>
            <div>
              <p>
                1.您通过我平台使用相关服务时，须同时遵守各项服务的单独协议。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>二、服务的使用及管理</h4>
            <div>
              <p>
                2.1
                当您使用一些功能时，我们会在获得您的同意后，收集您的一些隐私信息，例如提供测试服务时，我们需要您的姓名和生辰或其他个人信息进行分析；为了保存您的分析报告结果，我们需要获取您的手机或邮箱。如拒绝提供这些信息会使您无法使用相关特定功能。
              </p>
            </div>
            <div>
              <p>
                2.2
                在使用我平台服务时，请务必提供真实的个人信息，若您提供的信息有错误，会对测试结果的准确性造成偏差。我们保证您的所有个人信息除了用于生成测试数据以外，不会用于其他用途。
              </p>
            </div>
            <div>
              <p>
                2.3
                目前，我平台不会主动共享或转让您的个人信息至我们以外的第三方，如存在其他共享或转让您的个人信息情形时，我们会征得您的明示同意，此外我们会对对外提供信息的行为进行风险评估。
              </p>
            </div>
            <div>
              <p>
                2.4
                您清楚知悉购买的我平台存在有效期，有效期限为一年。过期后用户将无法查看付费内容，建议用户在有效期内自行保管。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>三、服务流程及收费价格：</h4>
            <div>
              <p>3.1 测算流程：</p>
              <img
                alt=""
                src="https://qiniu.zhiyunys.com/img/protocol.jpeg"
              />
            </div>
            <div>
              <p>
                3.2
                您应当清楚知悉您使用的测试服务流程，在您使用我平台测试服务视为您已阅读本协议并且了解测试服务的全部流程及需要提供的全部信息，并且同意提供上述信息。
              </p>
            </div>
            <div>
              <p>
                3.3
                您应当清楚知悉您使用的测试服务流程为有偿服务。我平台的测试服务按照68元/次/人的标准向您收取费用。
              </p>
            </div>
            <div>
              <p>
                3.4
                我平台对价格定价有最终解释权，我平台有权利随时调整价格，您使用我平台测试服务视为您同意本条款，并且同意我平台的服务价格，并以此对价开展服务。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>四、用户个人信息保护</h4>
            <div>
              <p>
                我们非常重视保护您的个人信息。我平台将按照本协议以及《我平台隐私政策》的规定收集、使用、储存和分享您的个人信息。本协议对个人信息保护规定的内容与上述《我平台隐私政策》有相冲突的，及本协议对个人信息保护相关内容未作明确规定的，均应以《我平台隐私政策》的内容为准。
              </p>
            </div>
            <div>
              <p>
                4.1
                如您认为您的个人信息被我平台超目的使用、或被其他用户侵权，请立即与我平台联系，并按照要求提供相应的证明资料，我平台将及时调查核实处理。
              </p>
            </div>
            <div>
              <p>
                4.2
                一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别的考虑，您可能无法修改初次登陆时的初始信息及其他验证信息。
              </p>
            </div>
            <div>
              <p>
                4.3
                我平台将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。
              </p>
            </div>
            <div>
              <p>
                4.4
                对于我平台会如何收集、使用、存储和保护您的个人信息及您享有何种权利，您还可以阅读《隐私政策》予以进一步了解。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>五、您的权利</h4>
            <div>
              <p>
                在您使用我平台期间，为了您可以更加便捷地访问、更正、删除您的个人信息，同时保障您撤回对个人信息使用的同意的权利，我们在产品设计中为您提供了相应的操作设置，您可以参考下面的指引进行操作，此外，我们还设置了投诉举报渠道，您的意见将会得到及时的处理。
              </p>
            </div>
            <div>
              <p>5.1 删除个人信息和报告</p>
            </div>
            <div>
              <p>（1）进入测试首页，点击“查询历史订单”；</p>
            </div>
            <div>
              <p>（2）选择报告，点击删除按钮；</p>
            </div>
            <div><p>5.2 投诉举报</p></div>
            <div>
              <p>
                您可以通过客服反馈您的问题，我们将在第一时间回复并尽我们最大的努力解决您的问题。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>六、您的行为规范</h4>
            <div>
              <p>
                6.1
                您在使用我平台期间，不得使用虚假身份信息，并应当妥善保存其账户信息和密码，由于您泄密所导致的损失需由您自行承担。如您发现他人冒用或盗用其账户或密码，或其账户存在其他未经合法授权使用之情形，应立即以有效方式通知本平台。您理解并同意本平台有权根据您的通知、请求或依据判断，采取相应的行动或措施，包括但不限于冻结账户、限制账户功能等，本平台对采取上述行动所导致的损失不承担除法律有明确规定外的责任。
              </p>
            </div>
            <div>
              <p>
                6.2
                您在使用我平台服务时须遵守法律法规，不得利用本平台从事违法违规行为，包括但不限于：
              </p>
            </div>
            <div>
              <p>
                （1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
              </p>
            </div>
            <div>
              <p>
                （2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
              </p>
            </div>
            <div>
              <p>（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
            </div>
            <div>
              <p>（4）发布、传送、传播广告信息及垃圾信息；</p>
            </div>
            <div>
              <p>（5）其他法律法规禁止的行为。</p>
            </div>
            <div>
              <p>
                6.3
                您不得利用我平台进行任何有损我们及其关联企业之权利、利益及商誉，或其他用户合法权利之行为。
              </p>
            </div>
            <div>
              <p>
                6.4
                为了响应国家相关政策规定，本平台所有测试服务暂不提供给未满18岁的未成年人使用，对您带来不便表示非常抱歉。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>七、免责声明</h4>
            <div>
              <p>
                7.1
                本平台与其他的在线使用的互联网网站一样,也会受到各种不良信息、网络安全和网络故障问题的困扰,包括但不限于：
              </p>
            </div>
            <div>
              <p>
                （1）其他用户可能会发布诈骗或虚假信息,或者发表有谩骂、诅咒、诋毁、攻击内容的,或者含有淫秽、色情、下流、反动、煽动民族仇恨等让人反感、厌恶的内容的非法言论；
              </p>
            </div>
            <div>
              <p>
                （2）其他用户可能会发布一些侵犯您或者其他第三方知识产权、肖像权、姓名权、名誉权、隐私权和/或其他合法权益的图片、照片、文字等资料；
              </p>
            </div>
            <div>
              <p>
                （3）因您个人未妥善保护账号，或因您将帐号转让、出借、销售或以任何脱离控制的形式交由他人使用，或因黑客行为导致帐号遭他人非法使用；
              </p>
            </div>
            <div>
              <p>
                （4）面临着诸如黑客攻击、计算机病毒困扰、系统崩溃、网络掉线、网速缓慢、程序漏洞等问题的困扰和威胁。例如，可能存在的百度快照抓取测试结果行为，这个是百度收录机制造成，并非我们主动泄露测试结果。同时我们已在技术上防止搜索引擎收录测试结果，严格保护您的测试数据，请您放心，如您发现自己的测试结果被百度快照收录，请点击：http://help.baidu.com/webmaster/add&nbsp;提交反馈，百度方确认后会进行删除。
              </p>
            </div>
            <div>
              <p>
                7.2
                您充分理解到：本协议上述的各种不良信息、网络安全和网络故障问题,并不是本平台或我们公司所导致的问题,由此可能会造成您感到反感、恶心、呕吐等精神损害,或者给您造成其他的损失,概由您自行承担,本平台或我们公司无须向您承担任何责任。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>八、法律责任</h4>
            <div>
              <p>
                8.1
                您理解并同意，我平台有权依合理判断对违反有关法律法规或本协议规定的行为追究责任，对违法违规的任何人士采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。
              </p>
            </div>
            <div>
              <p>
                8.2
                您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；我平台因此遭受损失的，您也应当一并赔偿。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>九、协议的更新、变更、签署</h4>
            <div>
              <p>
                9.1
                本协议内容同时包括我平台可能不定期发布的关于本服务的相关协议、服务声明、业务规则及公告指引等内容。上述内容一经正式发布，即为本协议不可分割的组成部分，请同样遵守。否则，请立即停止使用本服务。
              </p>
            </div>
            <div>
              <p>
                9.2
                您使用本服务即视为您已阅读并同意受本协议的约束。我平台有权在必要时修改本协议条款。您在享受各项服务时，可以在相关页面中查阅最新的协议条款。本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。
              </p>
            </div>
            <div>
              <p>
                9.3
                如您对本协议条款内容有任何疑问，可向平台客服咨询，一旦点击获取服务的相关按钮，或者已经体验了测试服务，则与我平台形成协议关系，应当受本协议条款的约束。
              </p>
            </div>
          </li>
          <li class="list-item">
            <h4>十、其他</h4>
            <div>
              <p>
                本协议自您选择开始我平台测试服务之时起生效，除非我平台终止本协议或者您丧失我平台用户资格，否则本协议始终有效。本协议终止并不免除用户根据本协议或其他有关协议、规则所应承担的义务和责任。
              </p>
            </div>
            <div>
              <p>
                本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
.getorder {
  font-size: 0.32rem;
  .protocol {
    padding: 0.3rem;
  }
  h3 {
    font-size: .3rem;
    text-align: center;
    padding-bottom: 0.2rem;
    margin: 15px 0;
    font-weight: bold;
  }
  .part {
    font-size: .29rem;
    padding-bottom: 0.1rem;
    p {
      line-height: .45rem;
      color: #333;
      text-align: justify;
      text-indent: 2em;
      margin-bottom: 0.1rem;
    }
  }
  .fix-order {
    position: fixed;
    z-index: 100;
    right: 0;
    top: 40%;
  }

  .fix-order img {
    width: 0.6rem;
  }

  img {
    width: 100%;
  }

  p {
    margin: 0;
  }
}
</style>