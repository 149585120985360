<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import "@/assets/reset.css";

export default {
  name: "App",
  mounted() {
    try {
      window.tbs_bridge.nativeExec("network", "type", 0, null);
    } catch (e) {
      console.error(e);
    }
    // // 如果支持 popstate （一般移动端都支持）
    // if (window.history && window.history.pushState) {
    //   // 往历史记录里面添加一条新的当前页面的url
    //   history.pushState(null, null, document.URL);
    //   // 给 popstate 绑定一个方法监听页面返回
    //   window.addEventListener("popstate", this.back, false); //false阻止默认事件
    // }
    //通过监听hashchange事件 判断当前页面的路由与实际的路由是否一致
    // window.addEventListener(
    //   "hashchange",
    //   () => {
    //     let currPath = window.location.hash.slice(1);
    //     if (this.$route.fullPath !== currPath) {
    //       // 不一致就跳转url的路由
    //       this.$router.push(currPath);
    //     }
    //   },
    //   false
    // );
  },
  // destroyed() {
  //   window.removeEventListener("popstate", this.back, false); //false阻止默认事件
  // },
  methods: {
    // back() {
    //   this.$router.go(-1);
    // },
  },
};
</script>

<style lang="less">
#app {
  max-width: 10rem;
  margin: 0 auto;
  position: relative;
  overscroll-behavior-x: none;
}
body {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
.fix-order {
  position: fixed;
  z-index: 100;
  right: 0;
  top: 40%;
}
.fix-order img {
  width: 0.6rem !important;
}
.fixed-menu {
  position: fixed;
  z-index: 2;
  .query-order {
    position: fixed;
    right: 0;
    top: 5.813333rem;
    width: 0.786667rem;
  }
  .complaint {
    position: fixed;
    right: 0;
    top: 9.6rem;
    width: 0.786667rem;
  }
}
</style>
