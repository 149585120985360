import api from '@/utils/request';

const order = {
  getList: (param) => api.post('/lucky/orderList', param), // 获取订单列表
  getOrder: (param) => api.post('/order/getOrder', param), // 根据订单号/手机号查询订单
  ceateOrder: (param) => api.post('/lucky/createOrder', param), // 创建订单
  deleteOrder: (param) => api.post('/lucky/delRecord', param), // 删除订单
  alipay: (param) => api.post('/lucky/alipay', param), // 支付宝支付
  wxpay: (param) => api.post('/lucky/wechatpay', param), // 微信支付
  alipay1: (param) => api.post('/lucky/alipay1', param), // 支付宝支付
  wxpay1: (param) => api.post('/lucky/wechatPay1', param), // 微信支付
  bindPhone: (param) => api.post('/lucky/bindingPhoneNumber', param), // 绑定手机号
  getWxOrder: (param) => api.post('/lucky/wechatOrderFind', param), // 查询微信订单
  getAliOrder: (param) => api.post('/lucky/aliOrderFind', param), // 查询支付宝订单
  getInfo: (param) => api.post(`/lucky/result`, param), // 订单详情
};

export default order;