/**
 * 接口默认配置
 */
import axios from 'axios';

let ajaxMethod = ['get', 'post', 'delete', 'put'];
let api = {};
// 创建axios 实例
const service = axios.create({
  baseURL: 'https://www.zhiyunys.com/api',
  timeout: 60000 * 5, // 请求超时时间
});
ajaxMethod.forEach(method => {
  api[method] = function (uri, data) {
    return new Promise(function (resolve, reject) {
      service[method](uri, data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  };
});
export default api;